import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import GoldDataDaily from '../../components/goldDataDaily';
import GoldData from '../../components/goldData';
export const _frontmatter = {
  "title": "Precio Oro",
  "image": "../../images/cotizacion_oro.jpg"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Cotización diaria oro:`}</h2>
    <p>{`Te mostramos el valor diario del oro en los últimos 30 días.`}</p>
    <GoldDataDaily mdxType="GoldDataDaily" />
    <br />
    <h2>{`Cotización histórica oro:`}</h2>
    <p>{`Aquí te mostramos la evolución del valor del oro desde sus inicios. `}</p>
    <GoldData mdxType="GoldData" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      